






import Vue from 'vue'
import store from '../../store/shop';

export default Vue.extend({
  components: {
    QTranslate: () => import("../../components/elements/QTranslate.vue"),
  },
  computed: {
    shopSetting() {
      return store.state.shopSetting;
    },
  },
  watch: {
    shopSetting: {
      deep: true,
      handler() {
        const prefix = this.$route.params.prefix;
        const shoppix = this.$route.params.shoppix;
        if (this.shopSetting.is_enable) {
            this.$router.push(`/${prefix}/${shoppix}/home`).catch(()=>{});
        }
      },
    },
  },
})
